import propTypes from 'prop-types'
import Script from 'next/script'

interface Props {
    adsenseId: string
}

const GoogleAdsense = ({ adsenseId }: Props) => {
    return (
        <Script
            async
            src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-${adsenseId}`}
            crossOrigin="anonymous"
            strategy="afterInteractive"
        />
    )
}

GoogleAdsense.propTypes = {
    adsenseId: propTypes.string.isRequired,
}

export default GoogleAdsense
