'use client'

import Script from 'next/script'
import { siteState } from '@lib/atoms'
import { SiteDataType } from '@lib/types/siteData.types'
import { useRecoilState } from 'recoil'

const GoogleAdsense: React.FC = () => {
    const [siteData] = useRecoilState<SiteDataType | null | any>(siteState)
    if (process.env.NODE_ENV !== 'production') {
        return null
    }
    return (
        <Script
            async
            src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-${siteData.adsenseId ? siteData.adsenseId : '3076620160263941'}`}
            crossOrigin="anonymous"
            strategy="afterInteractive"
        />
    )
}

export default GoogleAdsense
